<template>
 <div >
   <section class="seccion2 mb-5  container-fluid">
     <div class="">
<!--       src="../assets/imagenes/inicio/mariposa.png"-->
       <img
           src="../assets/imagenes/inicio/mariposa_completa.png"
           alt="mariposa"
           class="mariposa"
       />
     </div>
     <div class="cajatexto pe-2">
       <p class="texto textogris">
         Vivimos en una sociedad completamente inmersa en diseño gráfico, y
         aunque se encuentra en todas partes muy pocas veces nos damos cuenta
         de ello.<br /><br />
         Hoy en día, <I>hacer diseño gráfico</I> sin tener la menor idea si funciona o
         no, está al alcance de cualquier persona. Por ejemplo, en internet hay
         sitios en los que se pueden hacer logotipos en menos de un minuto. <br /><br />
         Todo es diseño y es válido, sin embargo ¿se le está ofreciendo al
         cliente lo que realmente necesita? y de ser así, ¿el diseño puede
         competir exitosamente en el mercado? <br /><br />
         Amo mi profesión y de alguna manera siempre estoy rescatándola. Me
         gusta mucho diseñar de manera tradicional y poner el corazón en cada
         diseño. Para mí, diseñar es un verdadero placer y más, sabiendo que el
         producto o servicio de cada cliente ha sido tratado de manera
         satisfactoria y exitosa. <br /><br />
         Ahora te invito a que conozcas una pequeña muestra de algunos de mis
         proyectos.<br />
       </p>
       <img
           src="../assets/imagenes/inicio/firma_texto.png"
           alt="firma lore"
           class="firma"
       />
     </div>
   </section>

   <section class="proyecto">
     <!---------------------------menu1----------------------------->

     <router-link style="text-decoration: none" to="/loredelatorre">
       <div class="seccionmenu1 container-fluid contab">
         <div class="row">
           <div class="col-8 d-flex justify-content-fluid">
             <p class=" titulomenu me-3 ">lore de la torre</p>
           </div>
           <div class="col-2 d-flex justify-content-fluid">
             <img
                 src="../assets/imagenes/inicio/lapiz.png"
                 alt="lapiz"
                 class="lapiz imghome"
             />
           </div>
         </div>
       </div>
     </router-link>
     <!---------------------------menu 2----------------------------->
     <router-link to="/cartelesposters" style="text-decoration: none">
       <div class="seccionmenu2 container-fluid contab">
         <div class="row">
           <div class="col-5 d-flex">
             <h2 class="menu2 titulomenu ps-5 me-0">carteles</h2>
           </div>
           <div class="col-2">
             <img
                 src="../assets/imagenes/inicio/catarina.png"
                 alt="catarina"
                 class="catarina"
             />
           </div>
           <div class="col-5 d-flex">
             <h2 class="menu2-2 titulomenu ms-0">posters</h2>
           </div>
         </div>
       </div>
     </router-link>

     <!---------------------------menu 3----------------------------->

     <router-link to="/identidadcorporativa" style="text-decoration: none">
       <div class="seccionmenu3 container-fluid contab">
         <div class="row">
           <div class="col-5 d-flex">
             <h2 class="menu3 titulomenu ps-4 me-0">identidad</h2>
           </div>
           <div class="col-2">
             <img
                 src="../assets/imagenes/inicio/cup_cake.png"
                 alt="cupcake "
                 class="cupcake"
             />
           </div>
           <div class="col-5 d-flex">
             <h2 class="menu3-3 titulomenu ms-0">corporativa</h2>
           </div>
         </div>
       </div>
     </router-link>
     <!---------------------------menu4 ----------------------------->

     <router-link to="/imagengrafica" style="text-decoration: none">
       <div class="seccionmenu4 container-fluid contab">
         <div class="row">
           <div class="col-5 d-flex">
             <h2 class="menu4 titulomenu ps-4 me-0">imagen</h2>
           </div>
           <div class="col-2">
             <img
                 src="../assets/imagenes/inicio/huella.png"
                 alt="huella"
                 class="huella"
             />
           </div>
           <div class="col-5 d-flex">
             <h2 class="menu4-4 titulomenu ms-0">gráfica</h2>
           </div>
         </div>
       </div>
     </router-link>

     <!---------------------------menu5 ----------------------------->

     <router-link to="/disenoeditorial" style="text-decoration: none">
       <div class="seccionmenu5 container-fluid contab">
         <div class="row">
           <div class="col-5 d-flex">
             <h2 class="menu5 titulomenu ps-4 me-0">diseño</h2>
           </div>
           <div class="col-2">
             <img
                 src="../assets/imagenes/inicio/conchita.png"
                 alt="conchita"
                 class="conchita"
             />
           </div>
           <div class="col-5 d-flex">
             <h2 class="menu5-5 titulomenu ms-0">editorial</h2>
           </div>
         </div>
       </div>
     </router-link>

     <!---------------------------menu6 ----------------------------->

     <router-link style="text-decoration: none" to="/contacta">
       <div class="seccionmenu6 container-fluid contab">
         <div class="row">
           <div class="col-7 d-flex justify-content-around">
             <h2 class="menu6 titulomenu me-3">contáctame</h2>
           </div>
           <div class="col-2">
             <img
                 src="../assets/imagenes/inicio/sobre.png"
                 alt="sobre"
                 class="sobre"
             />

           </div>
         </div>
       </div>
     </router-link>
   </section>

 </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  mounted() {
    this.$store.commit("SET_FOOTER", [false,'backverde']);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
