import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import './assets/css/estilo.css';
import './assets/css/estilo_lore.css';
import './assets/css/estilo_small_tablet.css';
import './assets/css/bootstrap/css/bootstrap.min.css'

// <link rel="stylesheet" href="assets/css/bootstrap/css/bootstrap.css" />
// <link rel="stylesheet" href="assets/css/bootstrap/css/bootstrap.min.css" />

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.api = process.env.VUE_APP_API_BASE_URL;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
