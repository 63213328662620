<template>
  <div id="app" :class="!FOOTER[0] ? 'd-flex flex-column h-100 body' : 'd-flex flex-column h-100'">
    <div id="">
      <section class="header">
        <div class="container-fluid backverde2">
          <div class="row pt-4 pb-4 mb-5">
            <!--------columna1----------->
            <div class="col-2 backrosa ps-4">
              <router-link style="text-decoration: none; " to="/loredelatorre">
                <img
                    src="./assets/imagenes/inicio/lapiz.png"
                    alt="lapiz"
                    class="lapiz2"
                />
                <p class="textoblanco tituloinicio  ">lore <br>
                  de la torre</p>
              </router-link>
            </div>

            <!--------columna2----------->
            <div style="padding-left: 2.5% ;" class="col-2 backverde ">
              <router-link style="text-decoration: none; " to="/cartelesposters">
                <img
                    src="./assets/imagenes/inicio/catarina.png"
                    alt="lapiz"
                    class="lapiz2 pt-2"
                />
                <p class="textoblanco tituloinicio  ">carteles posters</p>
              </router-link>
            </div>
            <!--------columna3----------->
            <div class="col-2 backnaranja ps-3">
              <router-link style="text-decoration: none; " to="/identidadcorporativa">
                <img
                    src="./assets/imagenes/inicio/cup_cake.png"
                    alt="lapiz"
                    class="cup2 pt-1"
                />
                <p class="textoblanco tituloinicio  ">identidad corporativa</p>
              </router-link>
            </div>
            <!--------columna4----------->
            <div style="padding-left: 3%;" class="col-2 backazul ">
              <router-link style="text-decoration: none; " to="/imagengrafica">
                <img
                    src="./assets/imagenes/inicio/huella.png"
                    alt="lapiz"
                    class="huella2 pt-2"
                />
                <p class="textoblanco tituloinicio  ">imagen gráfica</p>
              </router-link>
            </div>
            <!--------columna5----------->
            <div style="padding-left: 2%;" class="col-2 backmorado ">
              <router-link style="text-decoration: none; " to="/disenoeditorial">
                <img
                    src="./assets/imagenes/inicio/conchita.png"
                    alt="lapiz"
                    class="lapiz2 pt-2"
                />
                <p class="textoblanco tituloinicio  ">diseño editorial</p>
              </router-link>
            </div>

            <!--------columna6----------->
            <div class="col-2 backrojo ">
              <router-link style="text-decoration: none; " to="/contacta">
              <img
                  src="./assets/imagenes/inicio/sobre.png"
                  alt="lapiz"
                  class="sobre2 pt-2"
              />
              <p style="padding-top: 7%;" class=" textoblanco tituloinicio  ">contáctame</p>
              </router-link>
            </div>
          </div>
        </div>
      </section>
    </div>
    <router-view/>
    <Footer/>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  components: {
    Footer: () => import('./components/Footer/Footer'),
  },
  computed: {
    ...mapGetters(["FOOTER"]),
  }
};
</script>
<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.body{
  background: #2d440f;
}
</style>
