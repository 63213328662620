import Vue from 'vue'
import VueRouter from 'vue-router'
import Inicio from '../views/Inicio'

Vue.use(VueRouter)

const routes = [
  { path: "/", redirect: { name: "Inicio" } },
  {
    path: '/home',
    name: 'Inicio',
    component: Inicio
  },
  {
    path: '/loredelatorre',
    name: 'loredelatorre',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "loredelatorre" */ '../views/Lore')
  },
  {
    path: '/cartelesposters',
    name: 'cartelesposters',
    component: () => import(/* webpackChunkName: "cartelesposters" */ '../views/CartelesPosters')
  },
  {
    path: '/identidadcorporativa',
    name: 'identidadcorporativa',
    component: () => import(/* webpackChunkName: "identidadcorporativa" */ '../views/IdentidadCorporativa')
  },
  {
    path: '/imagengrafica',
    name: 'imagengrafica',
    component: () => import(/* webpackChunkName: "imagengrafica" */ '../views/ImagenGrafica')
  },
  {
    path: '/disenoeditorial',
    name: 'disenoeditorial',
    component: () => import(/* webpackChunkName: "disenoeditorial" */ '../views/DiseñoEditorial')
  },
  {
    path: '/contacta',
    name: 'contacta',
    component: () => import(/* webpackChunkName: "contacta" */ '../views/Contacta')
  },
  { path: "*", redirect: { name: "Home" } },
]

const router = new VueRouter({
  // mode: 'history',
  mode: process.env.NODE_ENV === 'development' ? 'history' : 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
